import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { translate } from "../utils"

const UserCheckInfo = () => {
  return (
    <Layout withBg>
      <div className="formWrapper userCheckInfo">
        <Container>
          <Row className="justify-content-center">
            <Col lg="7">
              <div className="text-left">
                <h2>
                  Bienvenue
                  <span className="mb-4">sur enter business</span>
                </h2>
                <p>
                  {translate('vous fait parie de ces nouveaux entrepreneurs qui dynamisent notre économie. Félicitations, pour ce nouveau projet et  bienvenue sur notre plateforme. Nous avons quelques informations que nous aimerions valider avec vous pour s’assurer de vous fournir le contenu le plus adapté possible à votre activité')}.
                </p>
                <Form>
                  <Row>
                    <Col lg="6">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder={translate("numéro d’entreprise")}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group>
                        <Form.Control type="text" placeholder={translate("Gérant")}/>
                      </Form.Group>
                    </Col>
                    <Col lg="9">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder={translate("numéro Nacebel")}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="3">
                      <Form.Group
                        controlId="formBasicCheckbox"
                        className="mt-3"
                      >
                        <Form.Check
                          className="text-certer"
                          type="checkbox"
                          label={translate("I don’t have a Nacebel Number",false)}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="9">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder={translate("Numéro d’entreprise")}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="3">
                      <Form.Group>
                        <Form.Control type="text" placeholder={translate("gérant")} />
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group>
                        <Form.Control
                          type="text"
                          placeholder={translate('Numéro d’entreprise')}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg="6">
                      <Form.Group>
                        <Form.Control type="text" placeholder={translate("gérant")} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group
                    controlId="formBasicCheckbox"
                    className="d-flex justify-content-center mt-3"
                  >
                    <Form.Check
                      className="text-certer"
                      type="checkbox"
                      label={translate("everything is correct")}
                    />
                  </Form.Group>
                  <a href={'/sign-up'}>
                  <div className="d-md-flex justify-content-center mt-3 form-group row">
                    <Button variant="success" type="submit" className="button-yellow">
                      {translate('continuer')}
                    </Button>
                    </div>
                  </a>
                  <small className="d-block pt-2 text-center text-secandory">
                    {translate('modifier des champs')}
                  </small>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default UserCheckInfo
